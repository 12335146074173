<template>
  <div class="home">
    <pc-header :active1="1"></pc-header>
    <div class="m60 main">
      <div class="banner">
        <el-carousel height="900px" style="width: 100%">
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <img style="width: 100%; height: 900px" :src="item.img_url" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="cmain">
        <div class="NavTop">
          <div class="NavTopTxt">
            <span>系列产品 /</span> <em>PRODUCTLINES</em>
          </div>
          <span class="NavTopMore" @click="toProdcuts">MORE</span>
        </div>
        <div class="News">
          <div class="PartnerScroll">
            <div class="hd">
              <span class="pageState"></span>
            </div>
            <div class="bds">
              <ul class="picList">
                <li
                  v-for="(item, index) in productList"
                  :key="index"
                  @click="toProdcuts"
                >
                  <a href="##">
                    <dl>
                      <dt>
                        <img
                          style="width: 400px; height: 400px"
                          :src="item.img_url"
                          alt=""
                        />
                        <!-- <img src="../assets/images/x1.jpg" alt="" /> -->
                      </dt>
                      <dd style="font-style: 24px">{{ item.title }}</dd>
                    </dl>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="application">
        <div class="cmain">
          <div class="NavTop">
            <div class="NavTopTxt">
              <span>业务合作 /</span> <em>COOPERATION</em>
            </div>
            <span class="NavTopMore" @click="toService">MORE</span>
          </div>
          <div class="AppList">
            <ul>
              <li
                v-for="(item, index) in cooperationList"
                :key="index"
                @click="toService"
              >
                <a href="##">
                  <img
                    :src="item.img_url"
                    alt=""
                    style="width: 545px; height: 354px"
                  />
                  <i class="ALMed">{{ item.title }}</i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="cmain">
        <div class="center">
          <ul>
            <li>
              <a href="##">
                <img src="../assets/images/d1.jpg" alt="" />
                <div class="CenterMed">
                  <div class="txt1"><span>智能科技 / </span> ABOUT US</div>
                  <div class="hen"></div>
                  <div class="txt2">
                    成立于2012年11月29日，注册资金1.2亿，目前企业规模在全国通航企业中排名第十，飞行员培训全国排名第一。
                  </div>
                </div>
              </a>
            </li>
            <li style="margin-right: 0">
              <a href="##">
                <img src="../assets/images/d2.jpg" alt="" />
                <div class="CenterMed">
                  <div class="txt1"><span>新闻动态 / </span> NEWS CENTER</div>
                  <div class="hen"></div>
                  <div class="txt2">
                    了解最新动态 <br />
                    掌握行业最新详情
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import pcHeader from "@/components/pc-header";
export default {
  components: {
    pcHeader,
  },
  data() {
    return {
      bannerList: [],
      productList: [],
      cooperationList: [],
    };
  },
  methods: {
    toProdcuts() {
      this.$router.push("/prodcuts").catch((err) => {});
    },
    toService() {
      this.$router.push("/service").catch((err) => {});
    },
    getBanner() {
      this.$fetch({
        url: "/api/index/bannerList",
      }).then((res) => {
        this.bannerList = res.data.data;
        console.log(this.bannerList);
      });
    },
    getProductBanner() {
      this.$fetch({
        url: "/api/index/goodsList",
      }).then((res) => {
        this.productList = res.data.data;
      });
    },
    getCooperationBanner() {
      this.$fetch({
        url: "/api/index/CooperationBanner",
      }).then((res) => {
        this.cooperationList = res.data.data;
      });
    },
  },
  created() {
    this.getBanner();
    this.getProductBanner();
    this.getCooperationBanner();
  },
};
</script>
